html, body {
  width: 100%;
  height: 100%;
  min-width: 320px;
  background-color: #ffed57;
  border: 0;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

img {
  max-width: 512px;
}

/*# sourceMappingURL=index.f08d16fa.css.map */
