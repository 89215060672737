html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    background-color: #FFED57;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 320px;
}

img {
    max-width: 512px;
}